body {
    background-image: url('../images/background.jpg');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
    background-attachment: fixed;
    background-color: #b0adab;
}

/* === Nav Bar === */

#side-bar {
    z-index: 1000;
    display: none;
    position: fixed;
    top: 43px;
    text-align: center !important;
    background-color: #616161;
    color: white;
    font-weight: bold;
    border-top: 1px solid #ccc;
}

#side-bar > div {
    text-align: center !important;
}

a {
    text-decoration: none;
}

#header {
    color: white;
    background-color: #616161;
}

#srs-title {
    font-size: 1.2em;
    font-style: italic;
    line-height: 42px;
}

@media only screen and (min-width: 416px) {
    #srs-title {
        font-size: 1.5em;
    }
}

.page-top {
    margin-top: 43px;
}

.btm-buffer {
    margin-bottom: 14vh;
}

.wrapper {
    max-width: 900px;
    /* max-width: 700px; */
    margin: 0 auto;
}

#fs-frm label {
    font-size: 1em !important;
}

/* === Common === */

.page {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 94vh;
    flex-direction: column;
    background-color: rgba(255,255,255,0.4);
}

.page-content {
    flex: 1;
}

.title-h {
    padding-top: 15px;
}

/* The main image on the page */
.page-img {
    width: 100%;
    height: auto;
}

/* === Footer === */
footer {
    font-size: 0.9em;
    text-align: center;
    background-color: #C0C0C0;
}
